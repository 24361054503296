<template>
    <div class="template-download">
        <div class="fly-breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem to="/">主界面</BreadcrumbItem>
                <BreadcrumbItem :to="'/template/'+b_group.id">{{'分组-'+b_group.name}}</BreadcrumbItem>
                <BreadcrumbItem>{{'模板-'+b_template.name}}</BreadcrumbItem>
                <BreadcrumbItem>下载</BreadcrumbItem>
            </Breadcrumb>
        </div>

        <Button class="template-download-btn" type="primary" icon="md-download">{{'下载模板-'+b_group.name+'-'+b_template.name}}</Button>
        <div ref="qrcode" class="template-download-qrcode"></div>
        <img :src="image" v-if="image!=null" class="template-download-img">
    </div>
</template>

<script>
export default {
    data(){
        let response = $.ajax({
            url: "/template/getById/"+this.$route.params.templateId,
            async: false,
            type: 'POST'
        });
        let res = JSON.parse(response.responseText);
        let gresponse = $.ajax({
            url: "/group/getById/"+res.groupId,
            async: false,
            type: 'POST'
        });
        let gres = JSON.parse(gresponse.responseText);
        return {
            b_group:gres,
            b_template:res,
            image:res.image?JSON.parse(res.image).src:null
        }
    },
    mounted(){
        let url = location.protocol+'//'+location.host+'/template/download/'+this.$route.params.templateId;
        new QRCode(this.$refs.qrcode, {
            text: JSON.stringify({
                type:'template',
                url:url
            }),
            width: 256,
            height: 256,
            colorDark : "#f60",
            colorLight : "#FFF",
            correctLevel : 1 
        });
    }
}
</script>

<style scoped>

.template-download{
    text-align: center;
    width: 100%;
    height: 100%;
}

.template-download-btn{
    width:300px;
    margin:50px auto;
}

.template-download-qrcode{
    width: 256px;
    height: 256px;
    margin: 50px auto;
}

.template-download-img{
    margin: auto auto;
}
</style>